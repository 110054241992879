<template>
  <div id="container" ref="chart" style="height: calc(100% - 40px)"></div>
</template>

<script>
import ChartService from '../services/chart.service'
import {createChart, generateCandlesData} from '@devexperts/dxcharts-lite';

let chart;

export default {
  name: "ChartTest",
  props: ['symbol', 'showExtra'],
  data() {
    return {}
  },
  methods: {
    drawChart() {


      const chart = createChart(this.$refs.chart, {
        colors: {
        },
        drawingsList: [
          {
            groupName: 'Your group name 1',
            drawings: ['line', 'extended_line'],
          },
          {
            groupName: 'Your group name 2',
            drawings: ['rectangle', 'arc'],
          }
        ],
        components: {
          chart: {
            showCandlesBorder: false
          },
          volumes: {
            visible: true,
            volumeBarSpace: 5,
            barCapSize: 10
          }
        }
      });
      const candles = generateCandlesData({withVolume: true});
      chart.setData({candles});
    }
  },
  async mounted() {
    const {candles, patterns} = await ChartService.getChartBySymbol(this.symbol);
    this.candles = candles.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).map((_, index) => {

      if (_.low !== null) {

        _.y1 = _.low;
        _.y2 = Math.min(_.open, _.close);
        _.y3 = Math.max(_.open, _.close);
        _.y4 = _.high;
      }
      _.index = index;
      _.originalIndex = index;
      _.formattedDate = _.date.split('T')[0];

      return _;
    });
    this.patterns = patterns;


    try {

      this.$nextTick(() => {

        this.drawChart();
      })
    } catch (e) {
      console.log(e)
    }
  }
}
</script>

<style scoped>

</style>
