<template>
  <div id="searchWrapper" style="width: 100%">
    <i class="ri-search-2-line"/>
    <input type="search"
           style="width: 100%"
           ref="searchInput"
           :placeholder="placeholder" :value="modelValue" @keyup.enter="$emit('enter')" @input="$emit('update:modelValue', $event.target.value)"/>

  </div>
</template>

<script>
export default {
  name: "SearchInput",
  props: ['modelValue', 'placeholder']
}
</script>

<style scoped lang="scss">

#searchWrapper {
  position: relative;
  z-index: 3;

  i {
    position: absolute;
    top: 50%;
    left: 10px !important;
    transform: translateY(-50%);
  }

  input {
    padding: 5px;
    border-radius: 50px;
    border: 2px solid var(--notficationBorderColor);
    width: 160px;
    transition: all .1s ease-in;
    appearance: none;
    padding-left: 30px !important;

    &:focus,
    &:active {
      outline: none;
    }

    &.autoWidth {
      width: auto;
    }

    @media(max-width: 660px) {
      width: 210px;
      margin-right: auto;
    }

    &:focus, &:active {
      border: 2px solid var(--highlightColor);
      background: var(--bgColor);
    }
  }
}

</style>
