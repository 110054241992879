<template>
  <section>
    <div class="topToolbar">
      <SearchInput placeholder="Enter multiple symbols and press Enter" v-model="searchQuery" @enter="findMatch"/>
    </div>

    <h3>Requested symbols</h3>
    <div v-for="match of query">

      <div class="match">
        <div class="symbol">
          <symbol-logo :medium="true" :symbol="match.symbol"/>
          {{ match.symbol }}
        </div>
        <div class="data">{{ match.name }}</div>
        <div class="data">{{ match.industry }}</div>
        <div class="data">{{ match.marketCap }}</div>
      </div>
      <p style="padding: 10px">{{match.showFull ? match.description : (match.description.substr(0, 340) + '...')}} <span class="toggle" @click="match.showFull = !match.showFull">{{match.showFull ? 'Hide' : 'Show'}}</span> </p>
    </div>

    <h3>Results</h3>
    <div class="results">
      <div v-for="match of matches">

        <div class="match">
          <div class="symbol">
            <symbol-logo :medium="true" :symbol="match.symbol"/>
            {{ match.symbol }}
          </div>
          <div class="data">{{ match.name }}</div>
          <div class="data">{{ match.industry }}</div>
          <div class="data">{{ match.marketCap }}</div>
        </div>
        <p style="padding: 10px">{{match.showFull ? match.description : (match.description.substr(0, 340) + '...')}} <span class="toggle" @click="match.showFull = !match.showFull">{{match.showFull ? 'Hide' : 'Show'}}</span> </p>
      </div>
    </div>

  </section>
</template>

<script>
import SearchInput from "../components/ui/controls/SearchInput";
import SymbolService from "../services/symbol.service";
import SymbolLogo from "../components/ui/symbolLogo";

export default {
  name: "SymbolMatching",
  components: {SymbolLogo, SearchInput},
  data() {
    return {
      searchQuery: null,
      matches: [],
      query: []
    }
  },
  methods: {

    async findMatch() {

      const symbols = this.searchQuery.toString().trim().split(/[ ]/g).map(symbol => symbol.toUpperCase().trim());
      const results = await SymbolService.findMatch(symbols)
      this.query = results.query;
      this.matches = results.matches.map(symbol => {

        symbol.showFull = false;
        return symbol;
      });
    }
  }
}
</script>

<style scoped lang="scss">

.match {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 10px 0;
  font-size: 18px;
    color: var(--lightYellow);

  .symbol {
    font-weight: bold;
    gap: 10px;
    display: flex;
    align-items: center;
    width: 100px;
  }
}

h3 {
  padding: 0 0 0 10px;
  margin-bottom: 0;
  font-size: 26px;
}

p{
  border-bottom: 1px solid var(--notficationBorderColor);
  padding: 0;
  margin: 0;
  line-height: 26px;
}

.toggle {
  color: var(--mbpGreen);
  background: var(--mbpGreenOpacity);
  padding: 2px 5px;
  border-radius: 4px;
  cursor: pointer;
}
</style>
