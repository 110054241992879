<template>
  <main class="chartArea" style="overflow: hidden">
    <div>
      <MBPInput v-model="symbol" @enter="getChartBySymbol()"/>
      <div id="chartWrapper">
      <ChartTest ref="chart" :show-extra="true" :symbol="symbol" v-if="symbol" :key="chartKey"/>
        </div>
    </div>
  </main>
</template>

<script>
import ChartService from '../services/chart.service';
import MBPInput from "../components/ui/controls/MBPInput";
import CandleChart from "../components/charts/CandleChart";
import ChartTest from "./ChartTest";



export default {
  name: "ChartView",
  components: {ChartTest, CandleChart, MBPInput},
  data() {
    return {
      chartKey: 0,
      chart: null,
      chartOptions: {},
      symbol: 'TSLA'
    }
  },
  async beforeMount() {


    await this.getChartBySymbol();
  },
  methods: {
    async getChartBySymbol() {


      this.chart = await ChartService.getChartBySymbol(this.symbol);
      this.chartKey++;

    }
  }
}
</script>

<style scoped lang="scss">

.chartArea {
  flex-direction: column;
}

#chartWrapper {
  height: calc(100vh - 35px);
}
</style>
