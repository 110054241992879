<template>
  <div class="page">
    <PageToolbar :title="`Trades`" :hasBack="!isInMainView">
      <MBPButton v-if="isInMainView" label="Add a new trade" class="action" @click="goToNewTrade()"></MBPButton>
    </PageToolbar>

    <router-view/>
  </div>
</template>

<script>
import PageToolbar from "../components/ui/PageToolbar";
import MBPButton from "../components/ui/controls/MBPButton";


export default {
  name: "TradesPVT",
  components: {MBPButton, PageToolbar},
  data () {
    return {}
  },
  computed: {
    isInMainView () {
      return this.$route.name === 'TradesTablePVT';
    }
  },
  methods: {
    goToNewTrade () {
      this.$router.push('/tradespvt/trade');
      this.$router.go(1);
    }
  }
}
</script>

<style scoped lang="scss">

</style>
