const PATH = '/notifications';

export default {
    async postNotification(notification) {

        return axios.post(`${PATH}`, notification);
    },
    async getTimedNotificationById(id) {

        return axios.get(`${PATH}/timed/${id}`);
    },
    async getTimedNotificationsList() {

        return axios.get(`${PATH}/timed`);
    },
    async postNewEmail(email) {

        return axios.post(`${PATH}/email`, email);
    },
    async getTimedEmailById(id) {

        return axios.get(`${PATH}/email/timed/${id}`);
    },
    async getTimedEmailsList() {

        return axios.get(`${PATH}/email/timed`);
    },
    async getNewsToTag({type, page, showAll}) {

        return axios.get(`${PATH}/important/list`, {params: {type, page, showAll}})
    },
    async updateTag({notificationId, type, isJunk, isImportant}) {

        return axios.post(`${PATH}/tag`, {notificationId, type, isJunk, isImportant})
    }
}
