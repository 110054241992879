import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Chart from '../views/Chart.vue'

// Trades
import Trades from '../views/Trades.vue'
import TradesTable from "../views/partials/trades/TradesTable";
import TradeDetails from "../views/partials/trades/TradeDetails";


// Trades Pivotrade
import TradesPVT from '../views/TradesPivotrade.vue'
import TradesTablePVT from "../views/partials/trades/TradesTablePVT";
import TradeDetailsPVT from "../views/partials/trades/TradeDetailsPVT";


// Signals *TBD*


// Notifications
import Notifications from "../views/Notifications";
import NotificationsTable from "../views/partials/trades/NotificationsTable";
import PostNotification from "../views/partials/trades/PostNotification";

// Emails
import Emails from "../views/Emails";
import EmailsTable from "../views/partials/trades/EmailsTable";
import PostEmail from "../views/partials/trades/PostEmail";

// Testing
import ChartTest from "../views/ChartTest";
import Patterns from "../views/Patterns";
import SymbolMatching from "../views/SymbolMatching";
import NewsTagging from '../views/NewsTagging';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name: 'Test',
    component: ChartTest
  },
  {
    path: '/chart',
    name: 'Chart',
    component: Chart
  }, {
    path: '/news',
    name: 'NewsTagging',
    component: NewsTagging
  },
  {
    path: '/matching',
    name: 'Matching',
    component: SymbolMatching
  },
  {
    path: '/patterns',
    name: 'Patterns',
    component: Patterns
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: Notifications,
    children: [{
      path: '',
      name: 'NotificationsTable',
      component: NotificationsTable
    }, {
      path: 'notification/:notificationId?',
      name: 'PostNotification',
      component: PostNotification
    }]
  },
  {
    path: '/emails',
    name: 'Emails',
    component: Emails,
    children: [{
      path: '',
      name: 'EmailsTable',
      component: EmailsTable
    }, {
      path: 'email/:emailId?',
      name: 'PostEmail',
      component: PostEmail
    }]
  },
  {
    path: '/trades',
    name: 'Trades',
    component: Trades,
    children: [{
      path: '',
      name: 'TradesTable',
      component: TradesTable
    },{
      path: 'trade/:tradeId?',
      name: 'TradeDetails',
      component: TradeDetails
    }]
  },
  {
    path: '/tradespvt',
    name: 'TradesPVT',
    component: TradesPVT,
    children: [{
      path: '',
      name: 'TradesTablePVT',
      component: TradesTablePVT
    },{
      path: 'trade/:tradeId?',
      name: 'TradeDetailsPVT',
      component: TradeDetailsPVT
    }]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
