<template>

  <section :key="formKey">
    <BigLoader v-if="isLoading"/>
    <div style="padding: 1rem; max-width: 960px; margin: 0 auto" v-else>
      <div class="field">
        <MBPInput label="Symbol" :error-note="errors.noSymbol" autocomplete="false" type="text" label-id="symbol"
                  v-model="opportunity.symbol"/>
      </div>

      <div class="field row">
        <label>
          Direction:
          <MBPDropDown
              dropdownLabel="Select status"
              style="margin-top: 5px;"
              :default-selected="opportunity.direction"
              @select="opportunity.direction = $event.value"
              :options="directionOptions"/>
        </label>

        <label>
          Type:
          <MBPDropDown
              dropdownLabel="Select status"
              style="margin-top: 5px;"
              :default-selected="opportunity.type"
              @select="opportunity.type = $event.value"
              :options="typeOptions"/>
        </label>

        <label>
          Date (leave empty if today):
          <br/>
          <div style="margin-top: 7px;">
            <DatePicker showTime hourFormat="24" v-model="opportunity.dateAdded" dateFormat="dd/mm/yy"/>
          </div>
        </label>
      </div>

      <div class="field">
        <MBPTextarea label="Trade notes (internal)"
                     v-model="opportunity.note"/>
      </div>


      <h2>Updates</h2>
      <TradeUpdate :update="opportunity"/>
      <MBPButton style="margin: 2rem auto; display: block;" class="action large" @click="saveTrade" label="Save trade"/>

    </div>
  </section>

</template>

<script>
import MBPInput from "../../../components/ui/controls/MBPInput";
import MBPDropDown from "../../../components/ui/controls/MBPDropDown";
import MBPTextarea from "../../../components/ui/controls/MBPTextarea";
import MBPButton from "../../../components/ui/controls/MBPButton";
import {QuillEditor} from '@vueup/vue-quill'
// Services
import TradeService from '../../../services/trades.service';
import MBPToggle from "../../../components/ui/controls/MBPToggle";
import BigLoader from "../../../components/ui/BigLoader";
import {DateTime} from "luxon";
import DatePicker from 'primevue/datepicker';
import TradeUpdate from "./TradeUpdate";

export default {
  name: "TradeDetailsPVT",
  components: {TradeUpdate, BigLoader, DatePicker, MBPToggle, MBPButton, MBPTextarea, MBPDropDown, MBPInput, QuillEditor},
  data() {
    return {
      showFollowup: null,
      showNewFollowUp: false,
      showOriginal: false,
      directionOptions: [{
        label: 'Bull',
        value: 'bull'
      },{
        label: 'Bear',
        value: 'bear'
      }],
      direction: [{
        label: 'Bull',
        value: 'bull'
      },{
        label: 'Bear',
        value: 'bear'
      }],
      typeOptions: [{
        label: 'Trade',
        value: 'trade'
      }, {
        label: 'Review',
        value: 'review'
      }],
      statusOptions: [{
        label: 'Pattern found',
        value: 'patternFound'
      }, {
        label: 'Possible breakout',
        value: 'possibleBreakout'
      }, {
        label: 'Failed',
        value: 'failed'
      }, {
        label: 'Closed',
        value: 'closed'
      }],
      quillBulletOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'link', 'clean'],
            ]
          }
        }
      },
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{header: [1, 2, 3, false]}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ]
          }
        }
      },
      isLoading: true,
      hasError: false,
      errors: {
        noSymbol: null,
        noStatus: null,
        noDirection: null,
        noHeContent: null,
        noEnContent: null
      },
      formKey: 0,
      newFollowup: {
        dateAdded: null,
        notification: null,
        bulletPoints: [],
      },
      newTradeBulletPoint: null,
      opportunity: {
        symbol: null,
        dateAdded: null,
        note: null,
        lastUpdate: null,
        direction: 'bull',
        type: 'trade',
        notification: null,
        bulletPoints: [],
        significantPriceLevels: [],
        followups: []
      }
    }
  },
  async beforeMount() {

    const opportunityId = this.$route.params.opportunityId;

    if (opportunityId) {

      this.opportunity = await TradeService.getTrade(opportunityId);
    }
    this.isLoading = false;
  },
  methods: {
    formatDate(date) {

      return !date.toString().includes('GMT') ? DateTime.fromISO(date).toFormat('DD') : DateTime.now().toFormat('DD');
    },
    clearErrors() {
      this.hasError = false;
      for (let k in this.errors) this.errors[k] = null;
    },
    isFieldEmpty(field) {
      return (field === null ||
          field === undefined ||
          field.toString().trim().length === 0);
    },
    async updateTrade(followupIndex = null, isNewFollowup = false) {

      try {

        await TradeService.updateTrade({
          opportunity: Object.assign(this.opportunity, isNewFollowup ? {isNewFollowup: true} : {}),
          opportunityId: this.$route.params.opportunityId,
          followupIndex,
          isNewFollowup
        });
        alert('Trade was updated.');
      } catch (e) {
        alert(e.message);
      }
    },
    async createTrade() {

      try {

        await TradeService.createTrade(this.opportunity);
        const confirm = window.confirm('New trade was created. Create another one?');
        if (confirm) {


          this.opportunity = {
            symbol: null,
            dateAdded: null,
            note: null,
            lastUpdate: null,
            direction: 'bull',
            type: 'trade',
            notification: null,
            bulletPoints: [],
            significantPriceLevels: [],
            followups: []
          }

          this.formKey++;
        } else {
          this.$router.push('/trades');
          this.$router.go(1);
        }
      } catch (e) {
        alert(e.message);
      }
    },
    async saveTrade() {

      this.clearErrors();
      if (this.isFieldEmpty(this.opportunity.symbol)) {
        this.errors.noSymbol = 'Field cannot be empty';
        this.hasError = true;
      }


      if (this.isFieldEmpty(this.opportunity.status)) {
        this.errors.noStatus = 'Field cannot be empty';
        this.hasError = true;
      }


      if (!this.hasError) {

        if (this.$route.params.opportunityId) {

          await this.updateTrade();

        } else {
          await this.createTrade();
        }
      } else {
        alert('Errors. Please check form.')
      }

    }
  }
}
</script>

<style scoped lang="scss">

@import "../../../assets/style/controls";
@import "../../../assets/style/texteditor";

section {
  overflow-y: auto;
  overflow-x: hidden;
  height: 92.3vh;
}

h2 {
  border-top: 1px solid var(--notficationBorderColor);
  padding-top: 1rem;
}


.textWrapper {
  padding: 0;
  box-sizing: border-box;
}

.borderless {
  border: none;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  color: white;
  resize: none;

  &:focus {
    outline: none;
    border: none;
  }
}

.toggle {
  cursor: pointer;
  border-radius: 8px;
  padding: 10px;
  font-weight: bold;
  background: var(--lightYellowOpacity);
  color: var(--lightYellow);
  margin: 0 0 1rem;
}

.followup {
  padding: 10px;
  border-radius: 8px;
  background: var(--tableHoverColorOpacity);
  margin-bottom: 10px;
}

.modal {
  position: fixed;
  border-radius: 12px;
  left: 50%;
  top: 1rem;
  height: calc(100vh - 2rem);
  transform: translateX(-50%);
  background: var(--bgColor);
  z-index: 9999;
  padding: 1rem;
  width: 960px;
  box-shadow: 0 0 500px 500px rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}


</style>
