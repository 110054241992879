<template>
  <main>
    <nav>
      News for:
      <button :class="{active: active === 'stocks'}" @click="loadNews('stocks', true)">Stocks</button>
      <button :class="{active: active === 'financials'}" @click="loadNews('financials', true)">Financial media</button>
      <button :class="{active: active === 'news'}" @click="loadNews('news', true)">Macro</button>


      <label>
        <input type="checkbox" @change="loadNews(active, true)" name="showAll" v-model="showAll" :value="true"/> Show all
      </label>
    </nav>


    <section id="notifications" ref="table">

      <table cellspacing="0">
        <thead>
        <tr>
          <th>Datetime</th>
          <th>Notification</th>
          <th>Symbols</th>
          <th>Tags</th>
          <th>Source</th>
          <th>Classify</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(notification, nIdx) in notifications" :key="nIdx + 'tr'">
          <td>{{ new Date(notification.datetime).toLocaleDateString('he-IL') }}
            {{ new Date(notification.datetime).toLocaleTimeString('he-IL') }}
          </td>
          <td class="message">
            <div v-if="notification.isJunk || notification.isImportant" class="classifyTag"
                 :class="{isJunk: notification.isJunk, isImportant: notification.isImportant}"/>
            {{ notification.title || notification.message }}
          </td>
          <td>
            {{ notification.symbols.join(', ') }}
          </td>
          <td>
            {{ notification.tags.join(', ') }}
          </td>
          <td>
            {{ notification.source }}
          </td>
          <td>
            <div class="classify">
              <MBPButton class="danger small" @click="updateClassification('isJunk', nIdx, notification._id)"
                         label="JUNK!"/>
              <MBPButton class="action small" @click="updateClassification('isImportant', nIdx, notification._id)"
                         label="IMPORTANT!"/>

              <MBPButton v-if="notification.isJunk || notification.isImportant" class="small"
                         @click="updateClassification(null, nIdx, notification._id)"
                         label="CLEAR"/>
            </div>
          </td>
        </tr>
        </tbody>
      </table>

      <br/>
      <MBPButton class="large action" @click="page++; loadNews(type); $refs.table.scrollTop = 0" label="NEXT PAGE"/>

    </section>
  </main>
</template>

<script>

import NotificationSevice from '../services/notifications.service'
import MBPButton from "../components/ui/controls/MBPButton";

export default {
  name: "NewsTagging",
  components: {MBPButton},
  data() {
    return {
      active: 'stocks',
      page: 0,
      notifications: [],
      showAll: false
    }
  },
  methods: {
    async updateClassification(type, index, notificationId) {

      if (type === 'isJunk') {

        this.notifications[index].isJunk = true;
        this.notifications[index].isImportant = false;
      } else if (type === 'isImportant') {

        this.notifications[index].isJunk = false;
        this.notifications[index].isImportant = true;
      } else {

        this.notifications[index].isJunk = false;
        this.notifications[index].isImportant = false;
      }

      await NotificationSevice.updateTag({
        notificationId,
        isJunk: this.notifications[index].isJunk,
        isImportant: this.notifications[index].isImportant,
        type: ['stocks', 'financials'].includes(this.active) ? 'stocks' : 'news'
      });
    },
    async loadNews(type, shouldReset = false) {

      this.active = type;
      if (shouldReset) this.page = 0;
      this.notifications = await NotificationSevice.getNewsToTag({
        type: this.active,
        page: this.page,
        showAll: this.showAll
      });
    }
  },
  mounted() {
    this.loadNews(this.active)
  }
}
</script>

<style scoped lang="scss">

main {
  flex-direction: column;
  overflow: hidden;
  height: 99vh;

  td, th {
    border-bottom: 2px solid var(--notficationBorderColor);
    border-right: 2px solid var(--notficationBorderColor);
    padding-bottom: 15px;
    padding-left: 15px;
  }
}

tbody tr {
  &:hover {
    background: var(--notficationBorderColor);
  }
}

.classify {
  display: flex;
  align-items: center;
  padding-right: 15px;
  gap: 15px;
}

#notifications {
  padding: 1rem;
  overflow-y: auto;
  flex: 0 1 100vh;

  .notification {
    border-bottom: 1px solid var(--notficationBorderColor);

    .flexRow {
      display: flex;
      align-items: center;
      gap: 15px;
      padding: 10px 0;
    }

    p {
      font-weight: 600;
      font-size: 18px;
      margin-bottom: 0;
    }

    small {
      display: block;
      margin: 0 0 5px 0;
      opacity: .8;
      padding: 0;
    }

    div {
      font-weight: 600;
    }
  }
}

button {
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
}

nav {
  position: sticky;
  top: 0;
  border-bottom: 1px solid var(--notficationBorderColor);
  width: 100%;
  padding: 1rem;
  background: var(--bgColor);
  align-items: center;
  height: fit-content;
  display: flex;
  flex-direction: row;
  gap: 15px;

  button.active {
    background: var(--green);
    color: white;
  }
}

.message {
  position: relative;

  .classifyTag {
    position: absolute;
    top: 15px;
    left: 0;
    width: 8px;
    height: 12px;

    &.isImportant {
      background: var(--green);
    }

    &.isJunk {
      background: var(--red);
    }
  }
}
</style>
