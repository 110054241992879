<template>
  <div>
    <h3>Bullet points</h3>


    <div class="bulletText bulletTextRow" v-for="(bullet, bIdx) of update.bulletPoints" :key="`tbp-${bIdx}`">
      <quill-editor theme="snow" :options="quillBulletOptions" contentType="html"
                    v-model:content="bullet.text"/>
      <MBPButton class="danger" @click="removeBulletPoint(bIdx)" label="Remove"/>
    </div>

    <div class="bulletText bulletTextRow">
      <quill-editor theme="snow" :options="quillBulletOptions" contentType="html"
                    v-model:content="bulletPoint"/>

      <MBPButton class="action" @click="addBulletPoint" label="Add"/>
    </div>
  </div>
</template>

<script>
import MBPButton from "../../../components/ui/controls/MBPButton";
import {QuillEditor} from '@vueup/vue-quill'

export default {
  name: "TradeUpdate",
  components: {MBPButton, QuillEditor},
  props: ['update'],
  data() {
    return {
      bulletPoint: null,
      quillBulletOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'link', 'clean'],
            ]
          }
        }
      },
      quillOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{header: [1, 2, 3, false]}],
              [{'list': 'ordered'}, {'list': 'bullet'}],
              ['link', 'image'],
              ['clean']
            ]
          }
        }
      },
    }
  },
  methods: {
    removeBulletPoint(bulletIndex) {

      this.update.bulletPoints.splice(bulletIndex, 1);
    },
    addBulletPoint() {
      this.update.bulletPoints.push({text: this.bulletPoint});
      this.bulletPoint = '<p></p>'
    }
  }
}
</script>

<style scoped lang="scss">


::v-deep {
  .notificationText {
    .ql-editor {
      min-height: 90px;
      height: 90px;
    }
  }

  .bulletText {
    .ql-container {
      flex: 1;
      width: 100%;
      border-bottom-right-radius: 8px;
      border-top-right-radius: 8px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0 !important;
      border: 1px solid var(--notficationBorderColor) !important;
      font-size: 12px !important;
    }

    .ql-toolbar {
      border-bottom-left-radius: 8px !important;
      border-top-left-radius: 8px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      border-right: none;
      padding: 9px;
    }

    .ql-editor {
      min-height: unset;
      height: unset;
      font-size: 14px;
      line-height: 11px;
      padding: 15px 1rem 0;
    }
  }
}


.bulletText, .bulletTextRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
  button.danger,
  button.action {
    margin-left: 15px;
    width: 80px;
  }
}
</style>
